$theme-font: 12px Arial, Helvetica, sans-serif;
$table-cell-border-color: #C8C8C8;
$input-border-default-color: #D8D8D8;
$input-border-hover-color: #8BF;
$input-border-focus-color: #5E9ED6;
$input-border-focus-shadow-color: #8DCDFF;

body {
  *:focus {
    border-color: $input-border-focus-color !important;
    box-shadow: 0 0 2px 2px $input-border-focus-shadow-color !important;
    outline: none;
  }

  font: $theme-font;

  .pi {
    font-size: 1em;
  }

  .p-button {
    font-size: 12px;
    padding: 0.1875rem 0.6875rem 0.125rem 0.6875rem;

    &.p-button-icon-only {
      min-width: 1.5rem;
      padding: 0.1875rem 0 0.125rem 0;
    }

    &.p-button-text-icon-left .p-button-text {
      padding: 0.25rem 1rem 0.25rem 2.1rem;
    }

    &.p-button-text-icon-right .p-button-text {
      padding: 0.25rem 2.1rem 0.25rem 1rem
    }

    &.p-button-text-only .p-button-text {
      padding: 0.25rem 1rem;
    }
  }

  .p-button.p-button-icon-only {
    width: auto;

    .p-button-text {
      padding: 0.25rem;
    }
  }

  .p-checkbox {
    height: auto;
    width: auto;

    .p-checkbox-box {
      border-radius: 2px;
      height: 0.875rem;
      line-height: 1rem;
      width: 0.875rem;

      .p-checkbox-icon {
        font-size: 10px;
      }
    }
  }

  .p-component {
    font: $theme-font;
  }

  .p-confirmdialog.p-dialog .p-dialog-content {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .p-datatable {
    .p-datatable-thead > tr > th {
      padding: 0.25em;
      text-align: center;
    }

    .p-datatable-tbody > tr > td {
      padding: 0.25em;
    }
  }

  .p-dialog {
    .p-dialog-header {
      font-size: 1rem;
      padding: 0.5rem 0.75rem;
    }

    .p-dialog-content {
      padding: 0.5625rem 1rem;
      position: relative;
    }

    .p-dialog-footer {
      border-top: none;
    }
  }

  .p-dropdown {
    min-width: 12.5em;

    &:not(.p-disabled).p-focus, &:not(.p-disabled):focus {
      border-color: $input-border-focus-color;
      box-shadow: 0 0 2px 2px $input-border-focus-shadow-color;
      outline: none;
    }

    .p-dropdown-trigger {
      width: 1.25rem;

      .p-dropdown-trigger-icon {
        font-size: 0.667rem;
      }
    }

    input {
      box-shadow: none !important;
    }
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font: $theme-font;
    padding: 0.25rem 0.5rem;
  }

  .p-dropdown-filter-icon {
    top: calc(50% + 2px);
  }

  .p-inputtext {
    font: $theme-font;
    font-size: 12px;
    padding: 0.1875rem 0.25rem;

    &.p-dropdown-label {
      padding-right: 0;
      user-select: none;
    }

    &:enabled:hover:not(.p-error) {
      border-color: $input-border-hover-color;
    }
  }

  .p-menu {
    background: linear-gradient(to bottom, #F6F7F9 0, #EBEDF0 100%);
    padding: 0.25rem;

    .p-menuitem {
      margin: 0.125rem 0;
    }

    .p-menuitem-link {
      padding: 0.25rem;
    }
  }

  .p-radiobutton {
    height: 14px;
    width: 14px;

    .p-radiobutton-box {
      margin: -3px;
      transform: scale(0.7);
    }
  }

  .p-slider {
    background: #838688;
    border: none;
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
    margin: 0 0.6rem;

    .p-slider-handle {
      background: transparent url("assets/theme/images/slider_handles@2x.png") 0 0 no-repeat !important;
      border: none !important;
      border-radius: 0;
      height: 42px !important;
      outline: none;
      position: absolute;
      transform: scale(0.5);
      transform-origin: 0 100%;
      width: 34px !important;
      z-index: 1;

      &.p-active {
        background-position: -34px 0 !important;
      }
    }

    &.p-slider-horizontal {
      height: 0.8rem !important;

      .p-slider-handle {
        margin-left: -0.7rem;
        top: -14px !important;
      }

      .p-slider-range {
        top: 0;
        height: 100%;
      }
    }

    .p-slider-range {
      border: 0;
      border-radius: 3px;
      -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
      -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6) inset;
      z-index: 1;
    }

    &.p-slider-vertical {
      height: 100px;
      width: 0.8rem !important;
      margin: 0.75rem 0.5rem;

      .p-slider-handle {
        left: -0.27rem !important;
        margin-bottom: -0.6rem;
        margin-left: 0;
        transform: scale(0.5) translateY(12px);
      }
    }
  }

  .p-default {
    border-color: $input-border-default-color;
  }

  .p-table {
    .p-table-tbody>tr>td,
    .p-table-thead>tr>th{
      padding: 0.25rem 0.5rem;
    }
  }

  .p-table-scrollable-view {
    border-bottom: solid 1px $table-cell-border-color;
  }

  .p-widget {
    font: $theme-font;
  }
}

.p-datatable-scrollable {
  &::before {
    border-top: 1px solid #c8c8c8;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &::after {
    border-bottom: 1px solid #c8c8c8;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  table {
    width: 100%;
  }

  .p-datatable-thead  {
    display: table-header-group !important;

    > tr {
      display: table-row !important;
      flex-wrap: initial !important;

      > th {
        display: table-cell !important;
        flex: initial !important;

        &::after {
          border-bottom: 1px solid #c8c8c8;
          content: '';
          display: block;
          height: 1px;
          left: 0;
          position: absolute;
          bottom: 0;
          width: 100%;
        }
      }
    }
  }

  .p-datatable-tbody {
    display: table-row-group !important;

    > tr {
      display: table-row !important;
      flex-wrap: initial !important;

      > td {
        display: table-cell !important;
        flex: initial !important;
      }
    }
  }

  .p-datatable-tfoot {
    display: table-footer-group !important;

    > tr {
      display: table-row !important;
      flex-wrap: initial !important;

      > td {
        display: table-cell !important;
        flex: initial !important;
      }
    }
  }
}
