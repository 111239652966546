@import 'primeng-theme-tweaks';

// Changes to @tubular/ng-widgets styling.
.tbw-dse-wrapper:hover:not([tabindex="-1"]) {
  border-color: #212121 !important;
}

.tbw-dse-header, .tbw-dse-wrapper, .tbw-dse-footer, .tbw-dse-button {
  border-color: #A6A6A6 !important;
}

tbw-calendar {
  font-size: 1.5em;
}

@mixin selectable($state) {
  -webkit-touch-callout: $state;
  -webkit-user-select: $state;
  -moz-user-select: $state;
  -ms-user-select: $state;
  user-select: $state;
}

@font-face {
  font-family: Astronomy;
  src: url("/assets/resources/Astronomy.ttf");
  unicode-range: U+263C-2653;
}

.small-caption {
  color: #00C;
  cursor: default;
  font-size: 75%;
  padding-bottom: 1px;
  @include selectable(none);
}

html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  background: white;
  color: black;
  font-size: 12px;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.appWrapper {
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  padding: 4px;
}

.error-caption {
  color: #C00;
  font-style: italic;
  font-size: 11px;
}

.constrained-dropdown .p-dropdown-label {
  display: inline-block;
  max-width: 143px;
  text-overflow: ellipsis;
}

.save-tooltip {
  .p-tooltip-arrow {
    border-bottom-color: green !important;
  }

  .p-tooltip-text {
    background-color: green;
    color: white;
    text-align: center;
  }
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(#007AD9, 0.6);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(#007AD9, 0);
  }
}
